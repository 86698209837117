<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <form id="CreateSize" @submit="CreateSize">
            <CCardHeader>
              <CCol col="6" style="font-size: 25px">إضافة حجم</CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CInput
                    label="الاسم"
                    id="name"
                    v-model="Size.name"
                    placeholder="ادخل اسم الحجم"
                    class="required"
                    required
                  />
                  <div v-if(errors.name) id="validateName" class="invalid-feedback">
                    {{errors.name}}
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary"
                ><CIcon name="cil-check-circle" /> حفظ</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger"
                ><CIcon name="cil-ban" /> مسح
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "CreateSize",
  data: function () {
    return {
      Size: {},
      image: "",
      errors: []
    };
  },
  methods: {
    CreateSize: function (e) {
      e.preventDefault();
      let currentObj = this;
      let formData = new FormData();
      formData.append("name", this.Size.name);
      console.log(formData);
      this.$http
        .post(`${process.env.VUE_APP_URL}sizes`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          currentObj.output = response.data;
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            currentObj.$router.push({ path: "/sizes" });
          } else {
            currentObj.errors = response.data.data;
          }
        })
        .catch(function (error) {
          currentObj.output = error;
        });
    },
  },
};
</script>
